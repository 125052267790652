<template>
  <Dialog v-model:visible="isOpen" :closable="false" :style="{ width: '50vw' }">
    <template #header>
      <h3>{{ title }}</h3>
    </template>

    <div>คะแนนความเครียดเป็นคะแนนที่ได้จากแบบประเมินความเครียด (ST5)</div>
    <div>
      ซึ่งเป็นแบบประเมินที่ใช้ในการประเมินความเครรียดในระยะ 2-4 สัปดาห์ที่ผ่านมา
      ประกอบด้วยหัวข้อคำถามดังต่อไปนี้
    </div>
    <div>1) มีปัญหาการนอน นอนไม่หลับหรือนอนมาก หรือไม่</div>
    <div>2) มีสมาธิน้อยลง หรือไม่</div>
    <div>3) รู้สึกงุดหงิด/กระวนกระวายใจ/ว้าวุ่นใจ หรือไม่</div>
    <div>4) รู้สึกเบื่อ เซ็ง หรือไม่</div>
    <div>5) รู้สึก ไม่อยากพบปะผู้คน หรือไม่</div>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => $emit('dimmer')"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalSts',
  props: ['is-open', 'title']
}
</script>

<style>
</style>
<template>
  <Dialog v-model:visible="isOpen" :closable="false" :style="{ width: '50vw' }">
    <template #header>
      <h3>{{ title }}</h3>
    </template>

    <div>
      คะแนนสมรรถภาพสมองเป็นคะแนนที่ได้จากแบบประเมินการทำงานของสมองด้านความรู้ความเข้าใจ
      (TMSE)
    </div>
    <div>
      ซึ่งเป็นแบบประเมินที่ใช้ในการคัดกรองคนไข้สมองเสื่อม
      เพื่อจำแนกความบกพร่องในการทำหน้าที่ของสมองด้านความรู้ความเข้าใจ
    </div>
    <div>และประเมินอาการสมองเสื่อมในผู้สูงอายุ โดยมีคะแนนเต็ม 30 คะแนน</div>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => $emit('dimmer')"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalTmse',
  props: ['is-open', 'title']
}
</script>

<style>
</style>
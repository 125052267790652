<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end print-hidden">
    <!-- right -->
    <div class="p-col-12 p-sm-3" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-11 p-sm-2">
      <router-link to="/dashboard">
        <Button label="กลับสู่หน้าหลัก" icon="pi pi-arrow-left"
      /></router-link>
    </div>
    <div class="p-col-1">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i>
      สมรรถภาพสมอง (TMSE) และภาวะเครียด (ST5)
    </template>
    <div>
      การวิเคราะห์ข้อมูลสมรรถภาพสมองและภาวะความเครียดของผู้สูงอายุ
      โดยแยกเปรียบเทียบสมรรถนะสมองกับศักยภาพการทำงานตามช่วงอายุ
      ความเครียดกับศักยภาพการทำงานตามช่วงอายุ เป็นต้น โดยการวิเคราะห์ในส่วนนี้
      จะเป็นการนำเสนอ
    </div>
    <div>1.ข้อมูลแสดงสมรรถภาพสมองกับความสามารถในการทำงานแยกตามช่วงอายุ</div>
    <div>2.ข้อมูลแสดงค่าเฉลี่ยสมรรถภาพสมองแยกตามช่วงอายุ</div>
    <div>
      3.ข้อมูลแสดงคะแนนประเมินความเครียดกับความสามารถในการทำงานแยกตามช่วงอายุ
    </div>
    <div>4.ข้อมูลแสดงภาวะความเครียดแยกตามช่วงอายุ</div>
    <div>5.ข้อมูลแสดงค่าเฉลี่ยความเครียดแยกตามช่วงอายุ และสถานะการทำงาน</div>
  </Fieldset>
  <Fieldset :legend="images['sum_tmse']">
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1 p-fluid">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="() => openModal('TMSE', images['sum_tmse'])"
        />
      </div>
    </div>
    <img :src="plots['sum_tmse']" style="width: 100%" />
    <DividerWord :content="words['TMSE01']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงค่าเฉลี่ยสมรรถภาพสมองแยกตามช่วงอายุ">
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1 p-fluid">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="
            () =>
              openModal('TMSE', 'กราฟแสดงค่าเฉลี่ยสมรรถภาพสมองแยกตามช่วงอายุ')
          "
        />
      </div>
    </div>
    <div ref="chartTmse" :style="graphStyle"></div>
    <DividerWord :content="words['TMSE02']" />
  </Fieldset>
  <Fieldset :legend="images['sum_sts']">
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1 p-fluid">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="() => openModal('STS', images['sum_sts'])"
        />
      </div>
    </div>
    <img :src="plots['sum_sts']" style="width: 100%" />
    <DividerWord :content="words['TMSE03']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงจำนวนผู้สูงอายุแยกตามคะแนนความเครียดตามช่วงอายุ">
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1 p-fluid">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="
            () =>
              openModal(
                'STS',
                'กราฟแสดงจำนวนผู้สูงอายุแยกตามคะแนนความเครียดตามช่วงอายุ'
              )
          "
        />
      </div>
    </div>
    <div ref="chartTstAvg" :style="graphStyle"></div>
    <DividerWord :content="words['TMSE04']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงค่าเฉลี่ยความเครียดแยกตามช่วงอายุ และสถานะการทำงาน">
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1 p-fluid">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="
            () =>
              openModal(
                'STS',
                'กราฟแสดงค่าเฉลี่ยความเครียดแยกตามช่วงอายุ และสถานะการทำงาน'
              )
          "
        />
      </div>
    </div>
    <div ref="chartTstSum" :style="graphStyle"></div>
    <DividerWord :content="words['TMSE05']" />
  </Fieldset>
  <ModalTMSE
    :is-open="isModalTMSE"
    :title="modalTitle"
    @dimmer="() => (isModalTMSE = false)"
  />
  <ModalSTS
    :is-open="isModalSTS"
    :title="modalTitle"
    @dimmer="() => (isModalSTS = false)"
  />
  <MoveToTopPage />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { imageEncode } from '../../Utility'
import { mixins } from '../Mixins'

import ModalTMSE from './ModalTMSE'
import ModalSTS from './ModalSTS'
import MoveToTopPage from '../../components/MovetoTop'
import DividerWord from './DividerWord'

// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      areaId: 'ALL',
      series: {
        tmseGender: null,
        sumStsAgeRange: null,
        sumStsCareer: null
      },
      plots: {
        sum_tmse: null,
        sum_sts: null
      },
      images: {
        sum_tmse: 'กราฟแสดงสมรรถภาพสมองกับความสามารถในการทำงานแยกตามช่วงอายุ',
        sum_sts:
          'กราฟแสดงคะแนนประเมินความเครียด (ST5) กับความสามารถในการทำงานแยกตามช่วงอายุ'
      },
      isModalTMSE: false,
      isModalSTS: false,
      modalTitle: null
    }
  },
  computed: {
    graphStyle() {
      return {
        height: '500px'
      }
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  watch: {
    series: {
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        const { tmseGender, sumStsAgeRange, sumStsCareer } = newVal
        this.clusterColumnTMSE(tmseGender)
        this.stackColumnTstAvg(sumStsAgeRange)
        this.clusterColumnTST(sumStsCareer)
      },
      deep: true
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('word/fetchGraphWords', {
        areaId: this.userArea
      })
      //**************** get graph api*************** */
      const data = await this.$store.dispatch(
        'pivot/fetchGraphSeriesAnalytise',
        {
          url: 'TMSE',
          areaId: this.userArea
        }
      )
      console.log('data ::==', data)
      this.series = data

      this.getGraphBarplot()
      this.pieCircle(data)

      //**************** get graph api*************** */
    },
    getGraphBarplot() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500
      let idx = 0
      Object.keys(this.images).forEach((name) => {
        setTimeout(
          async () => {
            this.plots[name] = 'img/analytics/'+this.areaId+'/'+name+'.png?'+new Date().getTime();
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })
    },
    openModal(modelType, modelTitle) {
      this.modalTitle = modelTitle
      if (modelType == 'TMSE') {
        this.isModalTMSE = true
      } else if (modelType == 'STS') {
        this.isModalSTS = true
      }
    },
    clusterColumnTMSE(tmseGender) {
      var chart = am4core.create(this.$refs.chartTmse, am4charts.XYChart)
      chart.colors.list = [am4core.color('#54ccfc'), am4core.color('#f7a0d5')]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'คะแนนสมรรถภาพสมอง'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }

      chart.data = [
        {
          category: '60 - 65  ปี',
          first: tmseGender?.count12,
          second: tmseGender?.count11
        },
        {
          category: '66 - 70  ปี',
          first: tmseGender?.count22,
          second: tmseGender?.count21
        },
        {
          category: '> 70  ปี',
          first: tmseGender?.count32,
          second: tmseGender?.count31
        }
      ]

      createSeries('first', 'ชาย')
      createSeries('second', 'หญิง')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    },
    stackColumnTstAvg(sumStsAgeRange) {
      var chart = am4core.create(this.$refs.chartTstAvg, am4charts.XYChart)
      console.log('sumStsAgeRange ::==' + JSON.stringify(sumStsAgeRange))
      // Add data
      chart.data = [
        {
          category: '0',
          value1: sumStsAgeRange?.count001,
          value2: sumStsAgeRange?.count002,
          value3: sumStsAgeRange?.count003
        },
        {
          category: '1',
          value1: sumStsAgeRange?.count011,
          value2: sumStsAgeRange?.count012,
          value3: sumStsAgeRange?.count013
        },
        {
          category: '2',
          value1: sumStsAgeRange?.count021,
          value2: sumStsAgeRange?.count022,
          value3: sumStsAgeRange?.count023
        },
        {
          category: '3',
          value1: sumStsAgeRange?.count031,
          value2: sumStsAgeRange?.count032,
          value3: sumStsAgeRange?.count033
        },
        {
          category: '4',
          value1: sumStsAgeRange?.count041,
          value2: sumStsAgeRange?.count042,
          value3: sumStsAgeRange?.count043
        },
        {
          category: '5',
          value1: sumStsAgeRange?.count051,
          value2: sumStsAgeRange?.count052,
          value3: sumStsAgeRange?.count053
        },
        {
          category: '6',
          value1: sumStsAgeRange?.count061,
          value2: sumStsAgeRange?.count062,
          value3: sumStsAgeRange?.count063
        },
        {
          category: '7',
          value1: sumStsAgeRange?.count071,
          value2: sumStsAgeRange?.count072,
          value3: sumStsAgeRange?.count073
        },
        {
          category: '8',
          value1: sumStsAgeRange?.count081,
          value2: sumStsAgeRange?.count082,
          value3: sumStsAgeRange?.count083
        },
        {
          category: '9',
          value1: sumStsAgeRange?.count091,
          value2: sumStsAgeRange?.count092,
          value3: sumStsAgeRange?.count093
        },
        {
          category: '10',
          value1: sumStsAgeRange?.count101,
          value2: sumStsAgeRange?.count102,
          value3: sumStsAgeRange?.count103
        },
        {
          category: '11',
          value1: sumStsAgeRange?.count111,
          value2: sumStsAgeRange?.count112,
          value3: sumStsAgeRange?.count113
        },
        {
          category: '12',
          value1: sumStsAgeRange?.count121,
          value2: sumStsAgeRange?.count122,
          value3: sumStsAgeRange?.count123
        },
        {
          category: '13',
          value1: sumStsAgeRange?.count131,
          value2: sumStsAgeRange?.count132,
          value3: sumStsAgeRange?.count133
        },
        {
          category: '14',
          value1: sumStsAgeRange?.count141,
          value2: sumStsAgeRange?.count142,
          value3: sumStsAgeRange?.count143
        },
        {
          category: '15',
          value1: sumStsAgeRange?.count151,
          value2: sumStsAgeRange?.count152,
          value3: sumStsAgeRange?.count153
        }
      ]

      chart.colors.list = [
        am4core.color('#A685E2'),
        am4core.color('#F38BA0'),
        am4core.color('#A7D0CD')
      ]

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'category'
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.minGridDistance = 1
      categoryAxis.title.text = 'คะแนนความเครียด (คะแนน)'

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      //valueAxis.renderer.inside = true
      //valueAxis.renderer.labels.template.disabled = true
      valueAxis.min = 0
      valueAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      // Create series
      function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.name = name
        series.dataFields.valueY = field
        series.dataFields.categoryX = 'category'
        series.sequencedInterpolation = true

        // Make it stacked
        series.stacked = true

        // Configure columns
        series.columns.template.width = am4core.percent(60)
        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.text = '{valueY}'
        labelBullet.locationY = 0.5
        labelBullet.label.hideOversized = true

        return series
      }

      createSeries('value1', '60 - 65')
      createSeries('value2', '66 - 70')
      createSeries('value3', '> 70')

      // Legend
      chart.legend = new am4charts.Legend()
      chart.legend.labels.template.text = 'อายุ {name}[/] ปี'
    },
    clusterColumnTST(sumStsCareer) {
      var chart = am4core.create(this.$refs.chartTstSum, am4charts.XYChart)
      chart.colors.list = [am4core.color('#a6d0cc'), am4core.color('#f28ba0')]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'คะแนนประเมินความเครียด'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }

      console.log('sumStsCareer ::==' + JSON.stringify(sumStsCareer))

      chart.data = [
        {
          category: '60 - 65  ปี',
          first: sumStsCareer?.count11,
          second: sumStsCareer?.count12
        },
        {
          category: '66 - 70  ปี',
          first: sumStsCareer?.count21,
          second: sumStsCareer?.count22
        },
        {
          category: '> 70  ปี',
          first: sumStsCareer?.count31,
          second: sumStsCareer?.count32
        }
      ]

      createSeries('first', 'ประกอบอาชีพ')
      createSeries('second', 'ไม่ได้ประกอบอาชีพ')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    }
  },
  components: {
    ModalTMSE,
    ModalSTS,
    MoveToTopPage,
    DividerWord
  }
}
</script>

<style>
</style>